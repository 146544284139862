const config = {
  apiKey: 'AIzaSyAH6pLp7wM0Z_z-c1NsZmlxSxrafkiF2Tw',
  authDomain: 'bipolarmooddiary.firebaseapp.com',
  databaseURL: 'https://bipolarmooddiary.firebaseio.com',
  projectId: 'bipolarmooddiary',
  storageBucket: 'bipolarmooddiary.appspot.com',
  messagingSenderId: '991534716809',
};

/*

const config = {
  apiKey: 'AIzaSyCIwwztAQaE-dADH-Ok4S64JMUdSeI6Fw4',
  authDomain: 'bipolarmoodtracker-development.firebaseapp.com',
  databaseURL: 'https://bipolarmoodtracker-development.firebaseio.com',
  projectId: 'bipolarmoodtracker-development',
  storageBucket: '',
  messagingSenderId: '522526764430',
};
*/
export default config;
